import React from 'react';
import Cart from './Cart';
import {CartContext} from './CartContext';
import {useContext} from 'react';
import {Card, CardContent, FormControl, Grid, InputLabel, Select, Button,
  Typography, TextField, LinearProgress, Fade, Checkbox, CircularProgress} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {RegularSpacer} from '../BasicComponents/Spacers';
import {aggregateOperationOnResults, operationRequiresInput,
  performOperationOnSnapshot} from '../../Utils/SnapshotHelper';
import {useEffect} from 'react';
import ClientPicker from '../UserInput/ClientPicker';
import {UserContext} from '../../contexts/userContext';
import {ErrorText} from '../Images/StyledComponents/BasicComponents';
import {ApiClient} from '../../Utils/ApiClient';

export const possibleOperations = Object.freeze({
  ChangeLane: 'Change Lane',
  ChangeSubLane: 'Change SubLane',
  ChangeCrop: 'Change Crop',
  ChangeStatus: 'Change Status',
  ChangeProjectId: 'Change Project ID',
  DownloadImages: 'Download Images',
  DownloadEntireSnapshot: 'Download Entire Snapshot',
  RemoveInstance: 'Remove Instance',
  CreateInstance: 'Create Instance',
  MatchInstance: 'Auto Match Instance',
  Delete: 'Delete',
  ScheduleForAnalysis: 'Schedule for analysis',
  ScheduleForAnalysisInstance: 'Schedule for instance analysis',
  CopySnapshotToClient: 'Copy Snapshot to Client',
  ExportSnapshotIds: 'Export Snapshot IDs',
  ExportData: 'Export Data',
  // TODO: Add Change MDC Height.
});

export const possibleStatus = Object.freeze({
  Created: 'created',
  Analyzed: 'analyzed',
  Discarded: 'discarded',
  Failed: 'failed',
  Deleted: 'deleted',
});


const ShoppingCartPage = () => {
  const {cartItems, addToCart, removeFromCart, removeAllFromCart, reloadCartItems} = useContext(CartContext);
  const {t} = useTranslation();
  const [selectedOperation, setSelectedOperation] = React.useState('');
  const [progress, setProgress] = React.useState(0);
  const [progressRounded, setProgressRounded] = React.useState(0);
  const [inProgress, setInProgress] = React.useState(false);
  const [keepItemsInShoppingCart, setKeepItemsInShoppingCart] = React.useState(true);
  const [operationInputValue, setOperationInputValue] = React.useState('');
  const [invalidInput, setInvalidInput] = React.useState(false);
  const [allowedOperations, setAllowedOperations] = React.useState([]);
  const [failedOperation, setFailedOperation] = React.useState(false);
  const [userInputValue, setUserInputValue] = React.useState('');

  const {user, getUserConfig, currentClient} = useContext(UserContext);

  useEffect(() => {
    const operations = getUserConfig()?.shopping_cart?.operations;
    const translatedOperations = [];
    if (operations) {
      for (let i = 0; i < operations.length; i++) {
        translatedOperations.push({'value': operations[i], 'translation': t(operations[i])});
      }
      setAllowedOperations(translatedOperations);
    }
  }, [t]);

  useEffect(() => {
    setOperationInputValue('');
    setInvalidInput(false);
    setProgress(0);
  }, [selectedOperation]);

  useEffect(() => {
    const roundTo = 10;
    setProgressRounded(Math.round(progress / roundTo) * roundTo);
  }, [progress]);

  const performOperation = async () => {
    if (operationRequiresInput(selectedOperation) && operationInputValue === '') {
      setInvalidInput(true);
      return;
    }

    setInProgress(true);
    setProgress(0);
    setFailedOperation(false);

    const progressionStep = 90 / cartItems.length;
    const results = [];
    const userConfig = getUserConfig();
    await Promise.all(
        cartItems.map(async (item, i) => {
          const result = await performOperationOnSnapshot(item, selectedOperation, operationInputValue, userConfig);
          if (result) {
            if (Array.isArray(result)) {
              results.push(...result);
            } else {
              results.push(result);
            }
          }
          setProgress((prevProgress) => prevProgress + progressionStep);
        }),
    );
    try {
      await aggregateOperationOnResults(results, selectedOperation);
    } catch (e) {
      console.error(e);
      setProgress(0);
      setFailedOperation(true);
      reloadCartItems();
      return;
    }
    setProgress(100);
    setInProgress(false);
    if (!keepItemsInShoppingCart) {
      removeAllFromCart();
    } else {
      reloadCartItems();
    }
  };

  const getOperationSpecificInputField = (operation) => {
    switch (operation) {
      case possibleOperations.ChangeLane:
        return <TextField fullWidth label="New lane" variant="outlined"
          error={invalidInput}
          value={operationInputValue} onChange={(e) => setOperationInputValue(e.target.value)} />;
      case possibleOperations.ChangeSubLane:
        return <TextField fullWidth label="New SubLane" variant="outlined"
          error={invalidInput}
          value={operationInputValue} onChange={(e) => setOperationInputValue(e.target.value)} />;
      case possibleOperations.ChangeCrop:
        return <TextField fullWidth label="New Crop" variant="outlined"
          error={invalidInput}
          value={operationInputValue} onChange={(e) => setOperationInputValue(e.target.value)} />;
      case possibleOperations.ChangeProjectId:
        return <TextField fullWidth label="New project ID" variant="outlined"
          error={invalidInput}
          value={operationInputValue} onChange={(e) => setOperationInputValue(e.target.value)} />;
      case possibleOperations.DownloadImages:
        return <FormControl>
          <InputLabel htmlFor="client-native-simple">{t('Image Type')}</InputLabel>
          <Select
            native
            value={operationInputValue}
            onChange={(event) => setOperationInputValue(event.target.value)}
            error={invalidInput}
          >
            <option aria-label="None" value="" />
            {getUserConfig()?.shopping_cart?.possibleImageTypes.map((item) => (
              <option key={item} value={item}>{t(item.toLowerCase() + '_image')}</option>
            ))}
          </Select>
        </FormControl>;
      case possibleOperations.ChangeStatus:
        return <FormControl>
          <InputLabel htmlFor="client-native-simple">{t('Status')}</InputLabel>
          <Select
            native
            value={operationInputValue}
            onChange={(event) => setOperationInputValue(event.target.value)}
            error={invalidInput}
          >
            <option aria-label="None" value="" />
            {Object.values(possibleStatus).map((item) => (
              <option key={item} value={item}>{item}</option>
            ))}
          </Select>
        </FormControl>;
      case possibleOperations.CopySnapshotToClient:
        return <ClientPicker hideCard={true}
          onClientChanged={(value) => setOperationInputValue(value)}
          clients={user.clients}
        />;
      case '':
        return <Typography>{t('No operation selected')}</Typography>;
      default:
        return '';
    }
  };

  // handle pasting snapshot IDs into the input field
  const handleSnapshotIdsInput = async () => {
    // Split by space, comma or newline
    const snapshotIds = userInputValue.split(/[\s, ]+/);
    // Remove empty strings
    const snapshotIdsWithoutEmpty = snapshotIds.filter((item) => item !== '');
    // Remove duplicates
    const snapshotIdsWithoutEmptyAndDuplicates = [...new Set(snapshotIdsWithoutEmpty)];

    // If no snapshot IDs entered
    if (snapshotIdsWithoutEmptyAndDuplicates.length === 0) {
      console.log('No snapshot IDs entered');
      setInvalidInput(true);
      return;
    }

    // Get snapshots from API
    const snapshots = await ApiClient.findSnapshotsById(snapshotIdsWithoutEmptyAndDuplicates);
    // Add snapshots to cart
    snapshots.forEach((snapshot) => {
      addToCart(snapshot);
    });
  };

  // handle user input change in the snapshot IDs input field
  const handleUserInput = (event) => {
    const value = event.target.value;
    setUserInputValue(value);
  };

  return (
    <div style={{'width': '70%'}}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8} md={6}>
          <Card>
            <CardContent>
              <Cart cartItems={cartItems} removeFromCart={removeFromCart} removeAllFromCart={removeAllFromCart} />
              {!currentClient &&
                <FormControl fullWidth style={{marginTop: 20}}>
                  <TextField label={t('Paste Snapshot IDs Here..')}
                    variant="outlined" multiline value={userInputValue} onChange={handleUserInput}/>
                  <Button color="primary" variant="contained"
                    onClick={handleSnapshotIdsInput}
                    style={{marginTop: 10, width: '30%', maxWidth: 150}}>{t('Submit')}</Button>
                </FormControl>}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Card>
            <CardContent>
              <div>
                <h2>{t('Perform group operation')}</h2>
                <FormControl>
                  <InputLabel htmlFor="client-native-simple">{t('Operation')}</InputLabel>
                  <Select
                    native
                    value={selectedOperation}
                    onChange={(event) => setSelectedOperation(event.target.value)}
                  >
                    <option aria-label="None" value="" />
                    {allowedOperations.map((item) => (
                      <option key={item.value} value={item.value}>{item.translation}</option>
                    ))}
                  </Select>
                </FormControl>
                <RegularSpacer size={3} />
                {selectedOperation ? getOperationSpecificInputField(selectedOperation) : ''}
                <RegularSpacer size={3} />
                <FormControl>
                  <div>
                    <Checkbox
                      checked={keepItemsInShoppingCart}
                      onChange={(event) => setKeepItemsInShoppingCart(event.target.checked)}
                      color="primary"
                    />
                    <span>{t('Keep items in cart after operation')}</span>
                  </div>
                </FormControl>
                <RegularSpacer />
                {selectedOperation && !inProgress ? <FormControl fullWidth>
                  <Button color="primary" variant="contained" onClick={performOperation}>{t('Submit')}</Button>
                </FormControl> : ''}
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  {inProgress ? <CircularProgress /> : ''}
                </div>
                <RegularSpacer size={5} />
                {progressRounded > 0 && inProgress ?
                  <LinearProgress variant="determinate" value={progressRounded} /> : ''}
                {progressRounded !== 0 && !inProgress ? <Fade in={true} timeout={500}>
                  <Typography>{t('Operation completed')}</Typography>
                </Fade> : ''}
                {failedOperation ? <ErrorText>{t('Operation Failed')}</ErrorText> : ''}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};


export default ShoppingCartPage;
