import React, {useState} from 'react';
import {useAnnotation} from '../../Annotations/AnnotationContext';
import {makeStyles} from '@material-ui/core';
import {usePlantSelection} from './PlantSelectionContext';
import {Snackbar} from '@mui/material';
import '../PlantBoxes.css';

const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover': {
      'backgroundColor': 'rgba(169, 169, 169, 0.5)',
    },
    'padding': theme.spacing(1),
  },
  flexVertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexVerticalBottomItem: {
    justifyContent: 'center',
  },
  flexVerticalFiller: {
    justifyContent: 'center',
    flexGrow: 1,
  },
  smallText: {
    fontSize: '12px', lineHeight: 'normal', color: 'white',
  },
  titleText: {
    width: '100%',
    fontSize: '14px',
    lineHeight: 'normal',
    color: 'white',
  },
}));

function divBorderStyle(annotation, color, downscalingRatio) {
  if (!annotation?.pixel_dimensions) {
    return {};
  }
  const center = annotation.center_coordinate.map((item) => item / downscalingRatio);
  const dimensions = annotation.pixel_dimensions.map((item) => item / downscalingRatio);
  const left = Math.round(center[0] - dimensions[0] / 2);
  const top = Math.round(center[1] - dimensions[1] / 2);
  const styleDict = {
    position: 'absolute',
    left: left + 'px',
    top: top + 'px',
    width: dimensions[0] + 'px',
    height: dimensions[1] + 'px',
    border: '2px solid ' + color,
    boxSizing: 'border-box',
  };
  return styleDict;
}


function PlantDiv({plant, downscalingRatio, snapshotId, setPlantInDropDown}) {
  const {getPlantAnnotation, pendingAnnotations} = useAnnotation();
  const {setSelectedPlant, selectedPlant} = usePlantSelection();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const getColor = (target) => {
    if (target?.healthy) {
      return 'green';
    } else {
      return 'pink';
    }
  };

  const convertTargetToRectangleStyle = () => {
    const color = getColor(plant);
    const styleDict = divBorderStyle(plant, color, downscalingRatio);
    if (selectedPlant?.plant_id === plant.plant_id) {
      styleDict['backgroundColor'] = 'rgba(35, 164, 85, 0.5)';
    }
    return styleDict;
  };

  const getTitleText = () => {
    let text = '';
    if (plant?.disease_classifications?.length > 0) {
      for (const disease of plant.disease_classifications) {
        text += disease.label + ' ' + Math.round(disease.confidence*100) + '%\n';
      }
    }
    if (text === '') {
      text = plant.plant_id;
    }
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
  };

  const getBottomText = () => {
    let text = '';
    const annotation = getPlantAnnotation(plant);
    if (annotation) {
      text += ' annotation';
      if (annotation?.label) {
        text += ': ' + annotation.label;
      } else {
        text += ': scheduled';
      }
    } else if (pendingAnnotations.includes(plant.plant_id)) {
      text += ' annotation: pending';
    }
    return text;
  };

  const onClick = () => {
    const text = `${plant.plant_id},${snapshotId}`;
    navigator.clipboard.writeText(text);
    setOpen(true);
    setSelectedPlant(plant);
    setPlantInDropDown(plant.plant_id);
  };

  return (
    <div style={convertTargetToRectangleStyle()} className='plant-box'
      onClick={onClick}>
      <div className='plant-box-tooltip'>
        <p >Height: {(plant.height*100).toFixed(1)} cm</p>
        {plant.dimensions &&
        <p >Dimensions: {parseInt(plant.dimensions[0]*100)} cm x {parseInt(plant.dimensions[1]*100)} cm</p>}
        <p >Buds: {plant.buds.length}</p>
      </div>
      <span className={`${classes.titleText} ${classes.flexVerticalFiller}`}>
        {getTitleText()}
      </span>
      <span className={`${classes.flexVerticalBottomItem} ${classes.smallText}`}>
        {getBottomText()}
      </span>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied plant ID to clipboard"
      />
    </div>
  );
}

export default PlantDiv;
